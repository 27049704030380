<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h3>About</h3>
    <p>
      Lee, a South African trans man - with <i>a lot of interests</i> - he has
      been growing roots in Auckland, New Zealand since 2009 after spending a
      couple of years in Australia when his family immigrated.
    </p>
    <p>
      He enjoys his furry companion, Sunny - the amazing groodle, various forms
      of staying active, feeling & thinking deeply, writing, creating
      meditations as tools, reading tarot, playing guitar and programming.
    </p>
    <p class="pullout">
      Check out
      <a href="https://vimeo.com/329531723" target="_blank" rel="noopener"
        ><strong>LEEWAY</strong></a
      >
      - now available to watch - directed by
      <a href="https://robertodirector.com/" target="_blank" rel="noopener"
        >Roberto Nascimento</a
      >
      - it's about the freedom to be yourself, regardless of what your body or
      society says.
      <i>Trigger warning: contains nudity.</i>
    </p>

    <h3>Connect</h3>
    <ul>
      <li>
        <a
          href="https://www.instagram.com/leemjac/"
          target="_blank"
          rel="noopener"
          >Instagram</a
        >
      </li>
      |
      <li>
        <a href="mailto:contact@leejacobsz.info" target="_blank" rel="noopener"
          >Email</a
        >
      </li>
    </ul>

    <h3>Digital Professional</h3>
    <ul>
      <li style="display: block">
        <h4>
          <a href="mailto:dev@leejacobsz.info" target="_blank" rel="noopener">
            >>> Digital Enquiries
          </a>
        </h4>
      </li>
      <li style="display: block">
        <a href="https://catchi.digital" target="_blank" rel="noopener"
          >Test Developer @ Catchi Digital</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://nz.linkedin.com/in/lee-jacobsz"
          target="_blank"
          rel="noopener"
          >LinkedIn</a
        >
      </li>
    </ul>

    <h3>Writing</h3>
    <ul>
      <li style="display: block">
        <h4>
          <a
            href="mailto:writer@leejacobsz.info"
            target="_blank"
            rel="noopener"
          >
            >>> Writing Enquiries
          </a>
        </h4>
      </li>
      <li style="display: block; margin-top: 5px; margin-bottom: 5px">
        <strong>
          <a
            href="https://leemjacobsz.medium.com/"
            target="_blank"
            rel="noopener"
            >Medium</a
          >
        </strong>
      </li>
      <li style="display: block">
        <a
          href="https://leemjacobsz.medium.com/coming-out-to-parents-can-be-scary-as-hell-11550c329f35?sk=6f19e60f0d1c6a4d5b2a4b7c8deb6d24"
          target="_blank"
          rel="noopener"
          >Coming out to your parents can be scary as hell</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://leemjacobsz.medium.com/want-to-lucid-dream-3753b41aeba9?sk=67a67e1e790d24759970a41687f677f5"
          target="_blank"
          rel="noopener"
          >Want to Lucid dream?</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://leemjacobsz.medium.com/stuck-this-helped-me-5c81e8293162?sk=6b1e64b6d6e9073fb2c0a81c963a8b62"
          target="_blank"
          rel="noopener"
          >Stuck? This helped me.</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://leemjacobsz.medium.com/will-transitioning-in-a-different-energy-make-it-easier-b53dd85ec26e?sk=bf12f4c00a59eaabc481603b693ca70a"
          target="_blank"
          rel="noopener"
          >Transitioning in a different energy.</a
        >
      </li>

      <li style="display: block; margin-top: 15px; margin-bottom: 5px">
        <a
          href="https://medium.com/transcognizant/do-you-identify-with-your-main-label-5caa3b5c7d10?sk=935b0b430dfefd91ab9e9ed4852d6216"
          target="_blank"
          rel="noopener"
          >Do you identify with your main label?</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://medium.com/transcognizant/the-importance-of-transgender-visibility-22df7b5a898"
          target="_blank"
          rel="noopener"
          >The Importance of Transgender Visibility.</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://medium.com/transcognizant/my-son-is-trans-and-this-is-how-i-reacted-97c3796aeb71?sk=980ab29a60c5de251b155c1ee794afbf"
          target="_blank"
          rel="noopener"
          >A mothers transition, with her trans son.</a
        >
      </li>
    </ul>

    <h3>
      Meditation
      <a
        href="https://www.youtube.com/c/LeeJacobsz_Meditation"
        target="_blank"
        rel="noopener"
        >Channel</a
      >
    </h3>
    <ul>
      <li style="display: block">
        <h4>
          <a
            href="mailto:meditation@leejacobsz.info"
            target="_blank"
            rel="noopener"
          >
            >>> Meditation Enquiries
          </a>
        </h4>
      </li>
      <li style="display: block"><h4>Meditations</h4></li>
      <li style="display: block">
        <a href="https://youtu.be/wUL7nzuMjFo" target="_blank" rel="noopener"
          >Performance Meditation for Actors / Performers</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://www.youtube.com/watch?v=_Q2Vx954kc8&t=3s"
          target="_blank"
          rel="noopener"
          >5 min Meditation - Watch the Mind</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://www.youtube.com/watch?v=VV6EMAUOX1k"
          target="_blank"
          rel="noopener"
          >Move on from Heartbreak Meditation</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://www.youtube.com/watch?v=rFQsPFRv7_g&t=107s"
          target="_blank"
          rel="noopener"
          >90 minute Relaxation - body scan | birdsong | rainsounds</a
        >
      </li>
    </ul>

    <h3>Tarot</h3>
    <ul>
      <li style="display: block">
        <h4>
          <a
            href="mailto:reading@leejacobsz.info"
            target="_blank"
            rel="noopener"
          >
            >>> Reading Enquiries
          </a>
        </h4>
      </li>
      <li style="display: block">
        <a
          href="https://www.instagram.com/sztarot/"
          target="_blank"
          rel="noopener"
          >Instagram</a
        >
      </li>
      <li style="display: block">
        <a
          href="https://www.youtube.com/channel/UC4nWV3QXgI8e90sDVT_4-VQ"
          target="_blank"
          rel="noopener"
          >Youtube</a
        >
      </li>
    </ul>

    <h3>Not for profit</h3>
    <ul>
      <li style="display: block">
        <a href="https://iamc.me" target="_blank" rel="noopener"
          >I AM C Pronoun Wristbands</a
        >
      </li>
    </ul>

    <h3>Film / Video / Acting</h3>
    <ul>
      <li style="display: block">
        <a href="https://vimeo.com/262268245" target="_blank" rel="noopener"
          >Sui Generis - Taylor (E1)</a
        >
      </li>
      <li style="display: block">
        <a href="https://vimeo.com/317648098" target="_blank" rel="noopener"
          >Bench</a
        >
      </li>
      <li style="display: block">
        <a href="https://vimeo.com/329531723" target="_blank" rel="noopener"
          >LEEWAY</a
        >
      </li>
      <li style="display: block">
        <a href="https://rurangi.com/" target="_blank" rel="noopener"
          >Rūrangi</a
        >
      </li>
    </ul>

    <h3>Media</h3>
    <ul>
      <li style="display: block">
        <a
          href="https://www.newshub.co.nz/home/lifestyle/2018/12/why-surgery-is-only-part-of-the-journey-to-transition.html"
          target="_blank"
          rel="noopener"
          >Newshub - Why surgery is only part of the journey to transition</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
  text-transform: uppercase;
}
h4 {
  margin-top: 0;
}
ul {
  list-style-type: none;
  padding: 10px;
  border: 1px solid #2c3e50;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #6b8e23;
}
.logo {
  border-radius: 50%;
  border: 7px solid #6b8e23;
}
.hello {
  max-width: 540px;
  margin: 0 auto;
}
li {
  margin: 10px;
}
p {
  text-align: left;
}
.pullout {
  text-align: center;
  padding: 30px 40px;
  border-top: 1px solid #2c3e50;
  border-bottom: 1px solid #2c3e50;
  margin: 32px auto;
  font-size: 1.2em;
}
</style>
